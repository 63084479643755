import React, { useContext, useMemo, useRef, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from '../../lib/nodash';
import { useSelector } from 'react-redux';
import { Box, Grid, ThemeContext } from 'grommet';
import { LazyMotion, m } from 'framer-motion';
import styled from 'styled-components';
import { StoryblokComponent } from 'gatsby-source-storyblok';
import StickyBox from 'react-sticky-box';

import ColorFilterPanel from '../Color/ColorFilterPanel';
import PaintCollectionCard from '../Paint/PaintCollectionCard';
import CollectionProductCount from '../Collection/CollectionProductCount';

import makeSelectFilteredPaintCollections from '../../state/product/makeSelectFilteredPaintCollections';
import useViewportSize from '../useViewportSize';
import { getProductPath } from '../../lib/product';
import useCollectionItemZoomSize from '../useCollectionItemZoomSize';
import { getColorFilterQueryParamString } from '../../lib/filters/filterRouteHelpers';
import useMobile from '../useMobile';

const loadFeatures = () =>
  import('../../lib/framer/motionMaxFeatures.js').then((res) => res.default);

const StyledGrid = styled(Grid)``;
const FilterContainer = styled(Box)`
  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    width: 20vw;
    padding-top: ${(p) => p.theme.global.edgeSize.large};
    max-width: 230px;
    min-width: 180px;
    border-right: ${(p) => p.theme.global.borderSize.small} solid;
  }
`;
const FilterCountContainer = styled(Box)`
  text-align: center;
  .filter-product-count {
    align-items: center;
  }

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    align-items: flex-start;
  }
`;

const DynamicBrowsePaintProductsComponent = ({
  pageTitle,
  pageSlug,
  defaultFinish,
  colCount = 1,
  queryParamString,
  pad,
  templateSize,
  srcSetSizes,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const isMobile = useMobile();
  return props.shopifyId ? (
    <PaintCollectionCard
      fill={true}
      height={{
        min: `calc((100vw - ${
          isMobile ? '0px' : theme.global.size.small
        }) / ${colCount})`,
      }}
      pad={pad}
      collection={props}
      detailsPath={getProductPath(props, defaultFinish)}
      srcSetSizes={srcSetSizes}
      hideSampleButton={templateSize === 'small' || templateSize === 'xsmall'}
      hideTags={templateSize === 'small' || templateSize === 'xsmall'}
      hideTitle={templateSize === 'xsmall'}
      size={templateSize}
      imageContainerProps={
        templateSize === 'xsmall'
          ? {
              pad: 0,
            }
          : {}
      }
      containerProps={
        templateSize === 'xsmall'
          ? {
              pad: 0,
            }
          : {}
      }
      collectionContext={{
        title: pageTitle,
        handle: pageSlug,
      }}
      overlay={templateSize === 'medium'}
      templateSize={templateSize}
    />
  ) : (
    <StoryblokComponent
      blok={props}
      style={{ gridColumn: `1 / span ${colCount}` }}
      queryParamString={queryParamString}
    />
  );
};

DynamicBrowsePaintProductsComponent.propTypes = {
  shopifyId: PropTypes.string,
  pageTitle: PropTypes.string,
  pageSlug: PropTypes.string,
  defaultFinish: PropTypes.string,
  colCount: PropTypes.number,
  queryParamString: PropTypes.string,
  templateSize: PropTypes.string,
  pad: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  srcSetSizes: PropTypes.string,
};

const BrowsePaintProducts = ({
  handleFilter,
  handleSort,
  handleReset,
  handleSearch,
  activeFilters = [],
  activeSorts = [],
  paintCollections,
  colorWheel,
  extraContent = [],
  defaultFinish = 'interior-standard',
  pageTitle,
  pageSlug,
  filterGroups = [],
  paintCollectionArrangement = [],
  ruleSet,
  baseUrl,
  hideFilters = false,
}) => {
  const selectFiltered = useMemo(makeSelectFilteredPaintCollections, []);
  const filterQuery = useSelector((state) => state.color.filterQuery);
  const gridRef = useRef(null);
  const isMobile = useMobile();

  const filteredPaintCollections = useSelector((state) =>
    selectFiltered(state, {
      paintCollectionArrangement,
      paintCollections,
      colorWheel,
      filterTags: activeFilters,
      sorts: activeSorts,
      ruleSet: ruleSet,
    })
  );

  const queryParamString = getColorFilterQueryParamString(activeFilters);

  const viewportSize = useViewportSize();

  const { colCount, templateSize, srcSetSizes } = useCollectionItemZoomSize();

  const allItems = [...filteredPaintCollections, ...extraContent];

  const gridItems = useMemo(
    () => [
      ...allItems.map((x, i) => {
        return {
          item: x,
          style: x.shopifyId
            ? undefined
            : {
                gridColumn: `1 / span ${colCount}`,
                gridRow: x.rowPosition
                  ? x.rowPosition === 'middle'
                    ? Math.ceil(allItems.length / colCount / 3)
                    : x.row
                  : x.row,
              },
          row: parseInt(x.row) || i,
          hideWhenFiltered: x.hideWhenFiltered,
        };
      }),
    ],
    [allItems, colCount]
  );

  const scrollToTop = useCallback(() => {
    if (gridRef.current && !isMobile) {
      gridRef.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [gridRef, isMobile]);

  return (
    <LazyMotion strict features={loadFeatures}>
      <Box>
        <Box flex={true} direction="row-responsive">
          <FilterContainer flex="grow">
            <StickyBox offsetTop={20} offsetBottom={20}>
              <ColorFilterPanel
                searchOptions={paintCollections}
                filterGroups={filterGroups}
                onFilter={(args) => {
                  scrollToTop();
                  handleFilter(args);
                }}
                onSearch={handleSearch}
                onSort={handleSort}
                activeFilters={activeFilters}
                activeSorts={activeSorts}
                onReset={() => {
                  scrollToTop();
                  handleReset();
                }}
                baseUrl={baseUrl}
                productCount={filteredPaintCollections.length}
                hideFilters={hideFilters}
              />
              <FilterCountContainer>
                <CollectionProductCount
                  className="filter-product-count"
                  count={filteredPaintCollections.length}
                  border={false}
                  align="start"
                  pad="medium"
                />
              </FilterCountContainer>
            </StickyBox>
          </FilterContainer>
          <Box>
            <StyledGrid
              fill="horizontal"
              pad="medium"
              columns={{ count: colCount, size: `${1 / colCount}%` }}
              ref={gridRef}
            >
              {gridItems
                .filter((z) => {
                  const filtersActive = activeFilters.length > 0;
                  const hidden =
                    z.hideWhenFiltered &&
                    (filtersActive ||
                      templateSize !== 'medium' ||
                      (filterQuery && filterQuery !== ''));
                  return !isNil(z.item) && !hidden;
                })
                .map((x) => {
                  return (
                    <m.div
                      key={x.item.id || x.item._uid}
                      layout
                      transition={{ duration: 0.4 }}
                      style={x.style}
                    >
                      <DynamicBrowsePaintProductsComponent
                        {...x.item}
                        pageTitle={pageTitle}
                        pageSlug={pageSlug}
                        colCount={colCount}
                        queryParamString={queryParamString}
                        defaultFinish={defaultFinish}
                        pad={viewportSize === 1 ? 'small' : undefined}
                        templateSize={templateSize}
                        srcSetSizes={srcSetSizes}
                      />
                    </m.div>
                  );
                })}
            </StyledGrid>
            <CollectionProductCount
              count={filteredPaintCollections.length}
              margin={{ top: 'large' }}
            />
          </Box>
        </Box>
      </Box>
    </LazyMotion>
  );
};

BrowsePaintProducts.propTypes = {
  handleFilter: PropTypes.func,
  handleSort: PropTypes.func,
  handleReset: PropTypes.func,
  handleSearch: PropTypes.func,
  activeFilters: PropTypes.array,
  activeSorts: PropTypes.array,
  paintCollections: PropTypes.array,
  paintCollectionArrangement: PropTypes.array,
  filterGroups: PropTypes.array,
  colorWheel: PropTypes.object,
  extraContent: PropTypes.array,
  defaultFinish: PropTypes.string,
  pageTitle: PropTypes.string,
  pageSlug: PropTypes.string,
  ruleSet: PropTypes.object,
  baseUrl: PropTypes.string,
  hideFilters: PropTypes.bool,
};

export default memo(BrowsePaintProducts);
