import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'grommet';
import useViewportSize from './useViewportSize';

function useCollectionItemZoomSize() {
  const viewportSize = useViewportSize();
  const collectionZoom = useSelector((state) => state.collection.zoom);
  const theme = useContext(ThemeContext);
  const mobileWidth = theme.global.breakpoints.small.value;
  let colCount = 1;
  let templateSize = 'medium';
  let srcSetSizes = `(max-width: ${mobileWidth}px) 100vw, (max-width: 800px) 70vw, (max-width: 1125px) 18vw, 12vw`;
  switch (viewportSize) {
    case 1: // mobile
      switch (collectionZoom) {
        case 1:
          colCount = 4;
          templateSize = 'xsmall';
          break;
        case 2:
          colCount = 2;
          templateSize = 'small';
          break;
        case 3:
          colCount = 1;
          templateSize = 'medium';
          break;
        default:
          colCount = 2;
          templateSize = 'small';
      }
      break;
    case 2:
      switch (collectionZoom) {
        case 1:
          colCount = 4;
          templateSize = 'xsmall';
          break;
        case 2:
          colCount = 2;
          templateSize = 'medium';
          break;
        case 3:
          colCount = 1;
          templateSize = 'medium';
          break;
        default:
          colCount = 2;
          templateSize = 'small';
      }
      break;
    case 3:
      switch (collectionZoom) {
        case 1:
          colCount = 5;
          templateSize = 'xsmall';
          break;
        case 2:
          colCount = 3;
          templateSize = 'medium';
          break;
        case 3:
          colCount = 2;
          templateSize = 'medium';
          break;
        default:
          colCount = 2;
          templateSize = 'medium';
      }
      break;
    case 4:
      switch (collectionZoom) {
        case 1:
          colCount = 6;
          templateSize = 'xsmall';
          break;
        case 2:
          colCount = 3;
          templateSize = 'medium';
          break;
        case 3:
          colCount = 3;
          templateSize = 'medium';
          break;
        default:
          colCount = 2;
          templateSize = 'small';
      }
      break;
    case 5:
      switch (collectionZoom) {
        case 1:
          colCount = 8;
          templateSize = 'xsmall';
          break;
        case 2:
          colCount = 4;
          templateSize = 'small';
          break;
        case 3:
          colCount = 2;
          templateSize = 'medium';
          break;
        default:
          colCount = 2;
          templateSize = 'small';
      }
      break;
    case 6:
      switch (collectionZoom) {
        case 1:
          colCount = 8;
          templateSize = 'xsmall';
          break;
        case 2:
          colCount = 6;
          templateSize = 'small';
          break;
        case 3:
          colCount = 4;
          templateSize = 'medium';
          break;
        default:
          colCount = 5;
          templateSize = 'small';
      }
      break;
    case 7:
      switch (collectionZoom) {
        case 1:
          colCount = 10;
          templateSize = 'xsmall';
          break;
        case 2:
          colCount = 6;
          templateSize = 'small';
          break;
        case 3:
          colCount = 4;
          templateSize = 'medium';
          break;
        default:
          colCount = 2;
          templateSize = 'small';
      }
      break;
    default:
  }

  srcSetSizes = `${80 / colCount}vw`;

  return { colCount, templateSize, srcSetSizes };
}

export default useCollectionItemZoomSize;
