import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';

const CollectionProductCount = ({ count, ...rest }) => (
  <Box
    pad="large"
    align="center"
    justify="center"
    fill="horizontal"
    border={{ side: 'top', size: 'xsmall', color: 'light-3' }}
    {...rest}
  >
    <Text size="small" weight={500}>
      {count} products
    </Text>
  </Box>
);

CollectionProductCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default CollectionProductCount;
