import queryString from 'query-string';
import {
  get,
  includes,
  concat,
  reject,
  replace,
  isEqual,
  compact,
  difference,
  filter as _filter,
  map,
} from '../nodash';
import {
  decodeQueryParams,
  StringParam,
  NumberParam,
} from 'serialize-query-params';

import { CommaArrayParam } from '../customParams';

const decodeParams = (searchParams) => {
  return decodeQueryParams(
    {
      tags: CommaArrayParam,
      tagGroups: CommaArrayParam,
      colorTags: CommaArrayParam,
      colors: CommaArrayParam,
      productTypes: CommaArrayParam,
      sort: StringParam,
      filter: CommaArrayParam,
      placement: NumberParam,
    },
    searchParams
  );
};

const getSearchParams = (location) => {
  return decodeParams(queryString.parse(location.search));
};
export const toParamString = (params) => {
  return queryString.stringify(params, { arrayFormat: 'comma' });
};

export const mergeSearchParams = (location, params) => {
  return toParamString({
    ...getSearchParams(location),
    ...params,
  });
};

const isColorTag = (tag) => includes('color:', get('slug', tag));
const isTagGroup = (tag) =>
  isEqual('StrapiTagGroupTag_groups', get('__typename', tag));
const isTagFeaturedFilter = (tag, featured) =>
  includes(get('slug', tag), map('slug', featured));

const getTagDiff = (tags, coll) => {
  const collection = Array.isArray(coll) ? coll : [coll];
  const withoutTags = difference(collection, map('slug', tags));
  return [...withoutTags, ...difference(map('slug', tags), collection)];
};

export const toggleColorFilter = (filter, location, featuredFilters) => {
  const { colors, colorTags } = getSearchParams(location);
  const newColors = compact(
    includes(get('slug', filter), colors)
      ? reject((x) => x === get('slug', filter), colors)
      : concat(get('slug', filter), colors)
  );

  const newColorTags = includes(get('slug', filter), colorTags)
    ? colorTags
    : reject(
        (x) =>
          isTagFeaturedFilter({ slug: x }, featuredFilters) ||
          map('slug', get('tags', filter)).indexOf(x) > -1,
        colorTags
      );

  return mergeSearchParams(location, {
    colors: newColors,
    colorTags: newColorTags,
  });
};

export const toggleProductTypeFilter = (filter, location, allColors = []) => {
  const { productTypes = [], colors } = getSearchParams(location);
  const newTypes = compact(
    includes(get('slug', filter), productTypes)
      ? reject((x) => x === get('slug', filter), productTypes)
      : concat(get('slug', filter), productTypes)
  );

  const newColorFilters = allColors
    .filter((color) => {
      return colors.includes(color.slug);
    })
    .filter((color) => {
      if (
        isFilteringPaintItemsOnly(newTypes) ||
        isFilteringWallcoveringsOnly(newTypes)
      ) {
        return color.tags.some((tag) => filter.slug === tag.slug);
      }
      return true;
    });

  return mergeSearchParams(location, {
    productTypes: newTypes,
    colors: map('slug', newColorFilters),
  });
};

export const toggleTagFilter = (filter, location, featuredFilters = []) => {
  const searchParams = getSearchParams(location);
  const { tagGroups = [] } = searchParams;
  const tagIsColorTag = isColorTag(filter);
  const tagIsTagGroup = isTagGroup(filter);
  const slug = get('slug', filter);

  if (tagIsTagGroup) {
    return mergeSearchParams(location, {
      tagGroups: includes(slug, tagGroups)
        ? reject((x) => isEqual(slug, x), tagGroups)
        : [...tagGroups, slug],
    });
  }

  const tagIsFeaturedFilter = isTagFeaturedFilter(filter, featuredFilters);
  const collectionKey = tagIsTagGroup
    ? 'tagGroups'
    : tagIsColorTag
    ? 'colorTags'
    : 'tags';
  const collection = searchParams[collectionKey];

  const newTags = getTagDiff(
    Array.isArray(filter) ? get('tags', filter) : [filter],
    collection
  );

  const colors = tagIsFeaturedFilter
    ? []
    : reject((x) => {
        return map('slug', get('items', filter)).indexOf(x) > -1;
      }, searchParams.colors);

  const colorTags = tagIsColorTag
    ? tagIsFeaturedFilter
      ? _filter((x) => {
          return isTagFeaturedFilter({ slug: x }, featuredFilters);
        }, newTags)
      : newTags
    : newTags;

  if (tagIsColorTag) {
    return mergeSearchParams(location, {
      colors: colors,
      colorTags,
    });
  } else if (tagIsTagGroup) {
    return mergeSearchParams(location, {
      colors: colors,
      tagGroups: newTags,
    });
  } else {
    return mergeSearchParams(location, {
      colors: colors,
      tags: newTags,
    });
  }
};

export const toggleGenericFilter = (
  newFilter,
  location,
  options = { removeNamespace: true }
) => {
  const slug = get('slug', newFilter);
  const searchParams = getSearchParams(location);
  const { filter = [] } = searchParams;
  const tagIsColorTag = isColorTag(newFilter);
  const paramName =
    tagIsColorTag && options.removeNamespace
      ? replace('color:', '', slug)
      : slug;

  const newParams = getTagDiff([{ ...newFilter, slug: paramName }], filter);
  return mergeSearchParams(location, { filter: newParams });
};

export const togglePlacement = (item, location) => {
  return mergeSearchParams(location, {
    placement: item,
  });
};

export const toggleSort = (sort, location) => {
  const params = getSearchParams(location);
  const activeSort = sort.slug
    ? isEqual(sort.slug, params.sort)
      ? undefined
      : sort.slug
    : isEqual(sort.id, params.sort)
    ? undefined
    : sort.id;
  return mergeSearchParams(location, {
    sort: activeSort,
  });
};

export const removeColorFilters = (colors, location) => {
  const params = getSearchParams(location);

  const newColors = reject(
    (x) => includes(x, map('slug', colors)),
    params.colors
  );
  return mergeSearchParams(location, {
    colors: newColors,
  });
};

export const getColorFilterQueryParamString = (activeFilters = []) => {
  const colorTagString = activeFilters.reduce((mem, filter, i) => {
    mem += i === 0 ? filter.slug : `,${filter.slug}`;
    return mem;
  }, '');
  return `?colorTags=${colorTagString}`;
};

export const extractAllFilters = (searchParams) => {
  const params = decodeParams(searchParams);
  return compact([
    ...params.tagGroups,
    ...params.colors,
    ...params.colorTags,
    ...params.productTypes,
    ...params.tags,
    ...params.filter,
    params.sort,
  ]);
};

const isFilteringWallcoveringsOnly = (activeFilters) =>
  activeFilters.some((filter) => {
    return filter === 'wallcoverings';
  }) && !activeFilters.some((filter) => filter === 'paint');

const isFilteringPaintItemsOnly = (activeFilters) =>
  activeFilters.some((filter) => {
    return filter === 'paint';
  }) && !activeFilters.some((filter) => filter === 'wallcoverings');
