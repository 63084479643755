import { useMemo, useContext } from 'react';
import { useWindowSize } from './useWindowSize';
import { ThemeContext } from 'grommet';

function useViewportSize() {
  const theme = useContext(ThemeContext);
  const [windowWidth] = useWindowSize();
  const viewportSize = useMemo(() => {
    return windowWidth <= theme.global.breakpoints.small.value // 768
      ? 1
      : windowWidth <= 800
      ? 2
      : windowWidth <= theme.global.breakpoints.medsmall.value //960
      ? 3
      : windowWidth <= 1125
      ? 4
      : windowWidth <= theme.global.breakpoints.medium.value // 1280
      ? 5
      : windowWidth <= theme.global.breakpoints.large.value
      ? 6
      : 7;
  }, [windowWidth]);

  return viewportSize;
}

export default useViewportSize;
